import React, { useState } from "react";
import styles from "./home.module.css";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  useAccordionButton,
} from "react-bootstrap";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { HiArrowSmRight } from "react-icons/hi";
import { FaPlay } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import HomePriceSection from "../../components/HomePriceSection";
import HomeSalesSection from "../../components/HomeSalesSection";
import HomeToolsSection from "../../components/HomeToolsSection";
import FooterImgSection from "../../components/FooterImgSection";
import GetInTouch from "../../components/GetInTouch";
import VideoModal from "../../components/VideoModal";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

function Home() {
  const navigate = useNavigate();
  const [openFaqAccordion, setOpenFaqAccordion] = useState(null);
  const [videoModal, setVideoModal] = useState(false);
  const [isPricingAnalysis, setIsPricingAnalysis] = useState(true);
  const [easyStepsCollapsed, setEasyStepsCollapsed] = useState("");

  let mainTitle =
    "Enable business transformation with AI-driven retail insights.";
  let subTitle =
    "Unlock the potential of your business with OttixHow’s advanced competitor analysis platform.";
  let marketTrend = "https://en.wikipedia.org/wiki/Market_trend";

  function CustomToggle({ children, eventKey }) {
    const faqOnClick = useAccordionButton(eventKey, () => {
      setOpenFaqAccordion(openFaqAccordion === eventKey ? null : eventKey);
    });

    const isOpen = openFaqAccordion === eventKey;

    return (
      <div
        onClick={faqOnClick}
        className="d-flex justify-content-between align-items-center cursor-pointer"
        style={{ cursor: "pointer" }}
      >
        <h6 className="p-2">{children}</h6>
        {isOpen ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
      </div>
    );
  }

  const handleToggle = () => {
    setIsPricingAnalysis(!isPricingAnalysis);
  };

  const handleCloseModal = () => {
    setVideoModal(false);
  };

  function CustomEasyStepsToggle({
    children,
    eventKey,
    isCollapsed,
    onToggle,
  }) {
    return (
      <div
        style={{ display: "flex", marginLeft: "8px", cursor: "pointer" }}
        onClick={() => onToggle(eventKey)}
      >
        <span>
          {isCollapsed ? (
            <AiOutlinePlus style={{ fontSize: "18px" }} />
          ) : (
            <AiOutlineMinus style={{ fontSize: "18px" }} />
          )}
        </span>
        <span style={{ marginLeft: "14px", fontSize: "18px" }}>{children}</span>
      </div>
    );
  }

  const handleSectionToggle = (eventKey) => {
    setEasyStepsCollapsed((prevKey) =>
      prevKey === eventKey ? null : eventKey
    );
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.ottixhow.com/" />
        <title>Elevate Your Strategy with Competitor Analysis Tool</title>
        <meta
          name="description"
          content="Unlock strategic insights with our competitor analysis tool. Gain a competitive edge through meticulous pricing tracking, trend analysis, and data visualization."
        />
        <meta
          name="keywords"
          content="Competitor Analysis,  Competitor Analysis Platform"
        />
        <meta
          property="og:title"
          content="Maximize Insights with Our Top Competitor Analysis Tool"
        />
        <meta
          property="og:description"
          content="Discover insights with our competitor analysis platform, designed to give you an edge in the market by uncovering the strategies and performance metrics of rivals."
        />

        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "OttixHow",
            "description": "Unlock strategic insights with our competitor analysis tool. Gain a competitive edge through meticulous pricing tracking, trend analysis, and data visualization.",
            "brand": {
              "@type": "Brand",
              "name": "OttixHow"
            },
            "offers": {
              "@type": "Service",
              "url": "https://www.ottixhow.com"
            }
          }
        `}
        </script>
      </Helmet>
      {videoModal && (
        <VideoModal
          videoModal={videoModal}
          handleCloseModal={handleCloseModal}
          url="https://www.youtube.com/embed/HWC_uVT_PBg?si=T92x_otp1fhF65KS&autoplay=1"
        />
      )}
      <div>
        <div className={styles.divContainer}>
          <Container fluid>
            <Row className="d-flex align-items-center vh-100">
              <Col md={6} className={` ${styles.homeTextContainer}`}>
                <div>
                  <h1>
                    Elevate Your Strategy with OttixHow's Competitor Analysis
                    Tool
                  </h1>
                  <p>
                    Discover a new era of competitive advantage with OttixHow,
                    the leading AI-based retail analytics platform. Harnessing
                    state-of-the-art technology, OttixHow delivers real-time
                    insights into competitor pricing strategies across diverse
                    marketplaces.
                  </p>
                  <p>
                    Empower your business with actionable data at your
                    fingertips, enabling informed decisions and strategic
                    growth.
                  </p>
                  <div className="d-flex aling-items-center text-center">
                    <Button
                      className={styles.learnBtn}
                      onClick={() => navigate("/competitor-analysis")}
                    >
                      Learn more
                      <HiArrowSmRight
                        style={{ fontSize: "1.5em", color: "white" }}
                      />
                    </Button>
                    <div
                      onClick={() => setVideoModal(true)}
                      className={styles.videoWrapper}
                    >
                      <div className={`${styles.videoMain}`}>
                        <div>
                          <div
                            className={`${styles.waves} ${styles.wave1}`}
                          ></div>
                          <div
                            className={`${styles.waves} ${styles.wave2}`}
                          ></div>
                          <div
                            className={`${styles.waves} ${styles.wave3}`}
                          ></div>
                        </div>
                        <div
                          className={`${styles.video} d-flex justify-content-center align-items-center`}
                        >
                          <FaPlay style={{ fontSize: "14px" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="d-flex justify-content-center align-items-center mt-3">
          <input
            type="checkbox"
            id="toggle"
            className={"toggleCheckbox"}
            checked={!isPricingAnalysis}
            onChange={handleToggle}
          />
          <label htmlFor="toggle" className={"toggleContainer"}>
            <span style={{ letterSpacing: "2px" }}>COMPETITOR ANALYSIS</span>
            <span style={{ letterSpacing: "2px" }}>SALES ANALYSIS</span>
          </label>
        </div>
        {isPricingAnalysis ? <HomePriceSection /> : <HomeSalesSection />}

        <GetInTouch />
        <div className="mt-4 text-center">
          <Container>
            <h5 style={{ letterSpacing: "2px" }}>WHY OTTIXHOW?</h5>
            <h2 style={{ color: "#faa61c", fontSize: "24px" }}>
              Revolutionize Your Business with Advanced Competitor Analysis
            </h2>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col md={4}>
                <Card className={styles.cardStyle}>
                  <div className="d-flex">
                    <div className={styles.whyOttixhowimgCont}>
                      <img
                        height="50"
                        width="50"
                        src="assets/ailogo.WebP"
                        alt="Automatic Product Mapping"
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <h3 className={styles.whyOttixhowHeading}>
                        AI-Powered Automatic Product Mapping
                      </h3>
                      <p className={styles.cardDesc}>
                        Experience unparalleled efficiency with our AI-powered
                        automatic product mapping feature. It streamlines the
                        categorization and organization of products, ensuring
                        you have accurate and structured data at your
                        fingertips.
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={4}>
                <Card className={styles.cardStyle}>
                  <div className="d-flex">
                    <div className={styles.whyOttixhowimgCont}>
                      <img
                        height="50"
                        width="50"
                        src="assets/lens.WebP"
                        alt="Price Tracking"
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <h3 className={styles.whyOttixhowHeading}>
                        Real-Time & Historical Price Tracking
                      </h3>
                      <p className={styles.cardDesc}>
                        Stay updated with real-time and historical price
                        tracking. Our competitor analysis solution provides
                        comprehensive insights into pricing trends and
                        fluctuations, enabling you to understand market dynamics
                        and adjust your pricing strategies accordingly.
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={4}>
                <Card className={styles.cardStyle}>
                  <div className="d-flex">
                    <div className={styles.whyOttixhowimgCont}>
                      <img
                        height="50"
                        width="50"
                        src="assets/marketlens.WebP"
                        alt="Heat Map & Visualization"
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <h3 className={styles.whyOttixhowHeading}>
                        Intuitive Heat Map & Visualization
                      </h3>
                      <p className={styles.cardDesc}>
                        Our intuitive heat map and visualization tools present
                        complex data in an easily interpretable format. Identify
                        patterns and trends effortlessly, aiding in strategic
                        decision-making and competitive analysis.
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Card className={styles.cardStyle}>
                  <div className="d-flex">
                    <div className={styles.whyOttixhowimgCont}>
                      <img
                        height="50"
                        width="50"
                        src="assets/marketpredict1.WebP"
                        alt="Predictive Analysis"
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <h3 className={styles.whyOttixhowHeading}>
                        Predictive Analysis for Proactive Strategies
                      </h3>
                      <p className={styles.cardDesc}>
                        Harness the power of predictive analysis to forecast
                        future{" "}
                        <a
                          style={{ color: "#faa61c" }}
                          target="_blank"
                          rel="noreferrer"
                          href={marketTrend}
                        >
                          market trends
                        </a>{" "}
                        and outcomes. Our competitor price tracking software
                        uses advanced algorithms to help you stay ahead of the
                        competition and make proactive business decisions.
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={4}>
                <Card className={styles.cardStyle}>
                  <div className="d-flex">
                    <div className={styles.whyOttixhowimgCont}>
                      <img
                        height="50"
                        width="50"
                        src="assets/report.WebP"
                        alt="Reports"
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <h3 className={styles.whyOttixhowHeading}>
                        Seamless Reports Integration
                      </h3>
                      <p className={styles.cardDesc}>
                        OttixHow offers seamless integration of customizable
                        reports, allowing you to tailor the data presentation to
                        your specific needs. Enjoy a smooth and personalized
                        experience that enhances your business insights.
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md={4}>
                <Card className={styles.cardStyle}>
                  <div className="d-flex">
                    <div className={styles.whyOttixhowimgCont}>
                      <img
                        height="50"
                        width="50"
                        src="assets/predictive.WebP"
                        alt="Data Import & Export"
                      />
                    </div>
                    <div style={{ flex: "1" }}>
                      <h3 className={styles.whyOttixhowHeading}>
                        Convenient Data Import & Export
                      </h3>
                      <p className={styles.cardDesc}>
                        Easily manage your data with our flexible import and
                        export options. OttixHow ensures that you can utilize
                        your data in any desired location, providing ultimate
                        convenience and control.
                      </p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container className="mt-4 mb-5">
            <Row>
              <Col>
                <h2 className={`${styles.heading}`}>
                  Understand OttixHow in 3 Easy Steps
                </h2>
              </Col>
            </Row>
            <Row className="mt-3 d-flex align-items-center">
              <Col className="p-0" md={6}>
                <Image
                  src="assets/accordionimage.WebP"
                  alt="Easy Steps"
                  className={`${styles.img}`}
                />
              </Col>
              <Col md={6}>
                <div>
                  <Accordion activeKey={easyStepsCollapsed}>
                    <Card style={{ border: "none" }}>
                      <Card.Header style={{ background: "none" }}>
                        <CustomEasyStepsToggle
                          eventKey="0"
                          isCollapsed={easyStepsCollapsed !== "0"}
                          onToggle={handleSectionToggle}
                        >
                          <h4 style={{ fontSize: "18px" }}>Data Collection</h4>
                        </CustomEasyStepsToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <p style={{ fontSize: "15px" }}>
                            Users can conveniently upload data according to
                            their specific needs, whether individual files or
                            bulk uploads. Our platform provides flexibility and
                            ease of data integration. Once data is uploaded,
                            OttixHow utilizes advanced algorithms to analyze and
                            interpret information, ensuring comprehensive
                            insights. This streamlined process saves time and
                            enhances efficiency, allowing businesses to focus on
                            strategic initiatives.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card style={{ border: "none" }}>
                      <Card.Header style={{ background: "none" }}>
                        <CustomEasyStepsToggle
                          eventKey="1"
                          isCollapsed={easyStepsCollapsed !== "1"}
                          onToggle={handleSectionToggle}
                        >
                          <h4 style={{ fontSize: "18px" }}>
                            Sorting & Matching
                          </h4>
                        </CustomEasyStepsToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <p style={{ fontSize: "15px" }}>
                            Utilising AI technology, we ensure your product data
                            aligns accurately with competitor data, delivering
                            precise insights for analysis. Our advanced
                            algorithms detect and eliminate inconsistencies,
                            ensuring reliable data synchronisation. This
                            guarantees that your decisions are based on accurate
                            and up-to-date information, enhancing your
                            competitive edge.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card style={{ border: "none" }}>
                      <Card.Header style={{ background: "none" }}>
                        <CustomEasyStepsToggle
                          eventKey="2"
                          isCollapsed={easyStepsCollapsed !== "2"}
                          onToggle={handleSectionToggle}
                        >
                          <h4 style={{ fontSize: "18px" }}>Data Validation</h4>
                        </CustomEasyStepsToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <p style={{ fontSize: "15px" }}>
                            Data validation at OttixHow ensures the accuracy and
                            integrity of your data, empowering businesses to
                            make informed decisions. Our robust validation
                            processes detect and correct errors, ensuring your
                            data is reliable and consistent . Trust OttixHow for
                            precise data validation to enhance your analytics
                            and drive better business outcomes.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card style={{ border: "none" }}>
                      <Card.Header style={{ background: "none" }}>
                        <CustomEasyStepsToggle
                          eventKey="3"
                          isCollapsed={easyStepsCollapsed !== "3"}
                          onToggle={handleSectionToggle}
                        >
                          <h4 style={{ fontSize: "18px" }}>
                            {" "}
                            Visualization & Reporting
                          </h4>
                        </CustomEasyStepsToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <p style={{ fontSize: "15px" }}>
                            Benefits from comprehensive and visually engaging
                            data visualization tools that simplify the
                            interpretation of complex data sets, helping you
                            make data-driven decisions. Interactive charts and
                            graphs enable deeper exploration of trends and
                            correlations, facilitating clear communication of
                            insights across teams. This enhances collaboration
                            and ensures alignment on strategic objectives.
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <HomeToolsSection />
        <FooterImgSection mainTitle={mainTitle} subTitle={subTitle} />

        <div className="mt-4 mb-4">
          <Container>
            <h2 className={`${styles.heading} mb-3`}>
              Frequently Asked Questions
            </h2>

            <Accordion>
              <Card>
                <Card.Header style={{ paddingBottom: "1px" }}>
                  <CustomToggle eventKey="0">
                    1. What is OttixHow's Competitor Price Tracking software?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="p-4">
                    OttixHow's competitor price tracking software is an
                    AI-powered software that monitors and analyzes competitor
                    pricing strategies in real-time and historically. It helps
                    businesses stay competitive by providing insights into
                    market pricing dynamics.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="mt-2">
                <Card.Header style={{ paddingBottom: "1px" }}>
                  <CustomToggle eventKey="1">
                    2. What makes OttixHow stand out as the best sales analysis
                    software?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="p-4">
                    OttixHow excels as the best{" "}
                    <span
                      style={{ color: "#faa61c", cursor: "pointer" }}
                      onClick={() => navigate("/sales-analysis")}
                    >
                      sales analysis
                    </span>{" "}
                    software due to its comprehensive dashboards providing
                    detailed insights into average monthly sales across regions,
                    stores, departments, and product types. With advanced
                    analytics, it helps businesses understand historical demand
                    patterns, customer behaviour, and emerging trends,
                    optimizing revenue and profitability effectively.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="mt-2">
                <Card.Header style={{ paddingBottom: "1px" }}>
                  <CustomToggle eventKey="2">
                    3. Is it a good idea to invest in competitor analysis tool
                    like OttixHow?
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="p-4">
                    Absolutely. OttixHow's competitor analysis tool provides
                    critical insights into your competitors' strategies, pricing
                    trends, and market positioning. This data empowers your
                    business to make informed decisions, optimize pricing
                    strategies, and stay ahead in a competitive market
                    landscape. Investing in OttixHow ensures you have the
                    necessary tools to enhance competitiveness, boost sales, and
                    achieve long-term business success.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Home;
