import React from "react";
import styles from "./contact.module.css";
import ContactForm from "../../components/contactForm/ContactForm";
import GetInTouch from "../../components/GetInTouch";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

function ContactUs() {
  return (
    <>
    <Helmet>
        <link rel="canonical" href="https://ottixhow.com/contact-us" />
        <title>
          Reach Out to OttixHow: Your Analytics Solutions Partner
        </title>
        <meta
          name="description"
          content="Contact OttixHow for expert analytics solutions and personalized support. Let us help you make data-driven decisions for your business. Request a demo today!"
        />

        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "OttixHow",
            "description": "Contact OttixHow for expert analytics solutions and personalized support. Let us help you make data-driven decisions for your business. Request a demo today!",
            "brand": {
              "@type": "Brand",
              "name": "OttixHow"
            },
            "offers": {
              "@type": "Service",
              "url": "https://www.ottixhow.com/contact-us"
            }
          }
        `}
        </script>
      </Helmet>
      <div className={styles.divContainer}>
        <div>
          <h1>We’re here for you!</h1>
          <h2>Complete our form, and we'll contact you promptly.</h2>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center text-center">
        <Container className="pt-5 pb-4">
          <Row>
            <Col>
              <h5 style={{ fontWeight: "bold" }}>
                Make your business future-ready by understanding your data
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 style={{ color: "#7a7e83", marginTop: "15px" }}>
                Prepare your business for the future with OttixHow's
                analytics-driven platform. Analyse your product and sales data
                to make informed decisions and stay ahead of competitors. Reach
                out today!
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <ContactForm />
      <GetInTouch />
      <div style={{ maxWidth: "100%", overFlow: "hidden" }}>
        <iframe
          title="Orbio Solutions"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7777.984856070895!2d77.651953!3d12.908208!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae148453748f87%3A0x5092ab9f9194a949!2sOrbio%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sus!4v1697463147028!5m2!1sen!2sus"
          width="100%"
          height="450"
          style={{ border: "0" }}
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
}

export default ContactUs;
