import React from 'react'
import {LiaLongArrowAltRightSolid} from "react-icons/lia"

function ScheduleDemoArrow({clr}) {

    const handleNavigateContact = ()=>{
      window.Calendly.initPopupWidget({ url: 'https://calendly.com/orbiosolutions' })
      return false
    }

  return (
    <div>
        <span onClick={handleNavigateContact} style={{color: clr, fontSize: "16px", cursor: "pointer" }}>
          Schedule a demo to know more
          <span>
            <LiaLongArrowAltRightSolid
              style={{ fontSize: "23px", marginLeft: "5px" }}
            />
          </span>
        </span>
      </div>
  )
}

export default ScheduleDemoArrow;