import React from "react";
import { Modal } from "react-bootstrap";

const VideoModal = ({ videoModal, handleCloseModal, url }) => {
  return (
    <div>
      <Modal show={videoModal} onHide={handleCloseModal} centered size="lg">
        <Modal.Header closeButton></Modal.Header>
        {/* <Modal.Body> */}
        <iframe
          allow="autoplay"
          title="video"
          width="100%"
          height="480"
          src={url}
          frameBorder="0"
          allowFullScreen
        ></iframe>
        {/* </Modal.Body> */}
      </Modal>
    </div>
  );
};

export default VideoModal;
