import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./homeprice.module.css";
import { useNavigate } from "react-router-dom";
import { GoDash } from "react-icons/go";

function HomePriceSection() {
  const navigate = useNavigate();

  return (
    <div className="mt-1">
      <Container>
        <Row className="d-flex align-items-center">
          <Col md={6} className={styles.colPadding}>
            <h5 className="d-flex align-items-center">
              <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
              COMPETITOR ANALYSIS
            </h5>
            <h2 className="mt-3 mb-3">
              Transform Your Business with OttixHow's Competitor Analysis
              Platform
            </h2>
            <p>
              Welcome to OttixHow, the ultimate{" "}
              <span onClick={() => navigate("/competitor-analysis")}>
                competitor analysis tool{" "}
              </span>
              designed to empower your business with cutting-edge insights into
              the competitive landscape. Leverage the power of our AI-driven
              competitor price-tracking software to stay ahead in the market,
              maximize sales, and make informed strategic decisions. OttixHow
              offers unparalleled visibility into market trends and competitor
              behaviour. Gain a competitive edge with OttixHow's intuitive
              interface and comprehensive analytics, tailored to meet the
              demands of modern businesses seeking growth and profitability.
            </p>
          </Col>
          <Col md={6} className="p-0">
            <Image
              src="assets/salesanalysis.WebP"
              className={`${styles.img} animate__animated animate__zoomIn`}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomePriceSection;
