import React from "react";
import FooterImgSection from "../../../components/FooterImgSection";
import BlogCards from "../../../components/BlogCards";
import { Col, Container, Row } from "react-bootstrap";

function AwardWinning() {
  let mainTitle =
    "Enable business transformation with AI-driven retail insights.";

  return (
    <>
      <div>
        <img
          src="/assets/7.WebP"
          alt="Award-Winning"
          style={{ height: "auto", width: "100%" }}
        />
      </div>
      <div>
        <Container>
          <h1 className="text-center pt-3">
            “Orbio Solutions: Pioneering AI-Driven Analytical Excellence with
            Award-Winning Product, OttixHow”
          </h1>
        </Container>
        <Container>
          <div
            className="mt-3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a href="https://www.orbiosolutions.com/">
              <img src="assets/orbioLogo.WebP" alt="orbioLogo" />
            </a>
          </div>
          <div className="mb-3 mt-3">
            <p>
              Orbio Solutions is one of the leading technology solution
              providers from India with Clients around the Globe. It’s scalable
              and innovative technology solutions, combined with insights from
              the robust data analytics allow businesses to gain a competitive
              edge and deliver exciting user experiences that help them fuel
              their growth. Orbio handhold their customers in a variety of
              business processes and requirements exclusively tailor made for
              them. Orbio solutions deals with Machine Learning & Artificial
              Intelligence that includes Technological Implementations such as
              Enterprises Application Services, Technology Consulting, Data
              Intelligence, Quality Engineering etc.
            </p>
          </div>
          <div>
            <h5>About OttixHow:</h5>
            <p>
              OttixHow is a robust, integrated retail analytics SaaS platform
              owned by Orbio that analyses your products and helps to predict
              customer demands. It enables you to utilize current trends,
              pricing models, and insights from your competitor’s and sales data
              to maximize your revenue and profitability in the ever-evolving
              global market. Using AI enabled technology, the platform quickly
              identifies the most suitable competitor from any marketplace or
              website.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Know Ottixhow Better :</span>{" "}
              Product Price Position – Obtain the pricing information of your
              competitors’ products, and compare their price points to your own
              products.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Live price check :</span>{" "}
              Easily assess the prices of your competitors with just a click.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Price Change Notifications :
              </span>{" "}
              Receive real time notifications
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Standardizing all prices into a single unit price :
              </span>{" "}
              Whether your competitor offers bulk or packaged products, gather
              the converted price details into a single unit price.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Historical Price Monitoring :
              </span>{" "}
              Gain insight into the historical price fluctuations of your
              competitor.
            </p>
          </div>
          <div>
            <h5 className="mb-4">Know Ottixhow Better </h5>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Product Price Position :
              </span>{" "}
              Obtain the pricing information of your competitors’ products, and
              compare their price points to your own products.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Live price check :</span>{" "}
              Easily assess the prices of your competitors with just a click.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Price Change Notifications :
              </span>{" "}
              Receive real time notifications
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Standardizing all prices into a single unit price :
              </span>{" "}
              Whether your competitor offers bulk or packaged products, gather
              the converted price details into a single unit price.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Historical Price Monitoring :
              </span>{" "}
              Gain insight into the historical price fluctuations of your
              competitor.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ height: "auto", width: "100%" }}
              src="assets/ottixhowlisting.WebP"
              alt="ottixhow Dashboard"
            />
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Map Violation :</span>{" "}
              Identify any resellers who are violating the desired price range.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Basket Analysis :</span> Gain
              insights into customer purchasing patterns.
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>Sales Forecasting :</span>{" "}
              Analyze the customer demand and predict sales
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Top Performing Products & Categories :
              </span>{" "}
              Evaluate the performance of your products and categories
            </p>
          </div>
          <div>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Across Geographical Locations :
              </span>{" "}
              Obtain a comprehensive competitive analysis for any of your global
              competitors.
            </p>
          </div>
          <div>
            <h5>Features of Ottixhow:</h5>
            <ul>
              <li>Ai – Powered Automatic Product Mapping</li>
              <li>Real-Time & Historical Price Tracking</li>
              <li>Intuitive Heat Map & Visualization</li>
              <li>Predictive Analysis</li>
              <li>Seamless Reports Integration</li>
            </ul>
          </div>
          <div>
            <Row>
              <Col md={6}>
                <h5>The Man Behind</h5>
                <p>
                  The able leadership and vision of our founder CEO – Rakeshnath
                  Oopath has been a true differentiator for Orbio over the
                  years. Rakeshnath is a technology leader with close to two
                  decades of multi-disciplinary expertise in leadership
                  positions. He comes with an immense wealth of professional
                  experience in healthcare, publishing, retail and software
                  services verticals. With his years of global project
                  management, what Rakeshnath brings on to the table is an
                  invaluable mix of leadership, technology and operational
                  expertise. His passion for developing high quality teams and
                  building long and sustainable relationships has positively
                  impacted the organization in multiple ways. Rakeshnath has a
                  keen eye to identify key market trends and innovative
                  technologies ahead of the competition thereby enabling Orbio
                  in delivering creative and unique solutions.
                </p>
              </Col>
              <Col md={6}>
                <img
                  alt="rakesh"
                  style={{ height: "auto", width: "100%" }}
                  src="assets/rakesh.WebP"
                />
              </Col>
            </Row>
          </div>
        </Container>
        <Container>
          <Row>
            <div style={{ padding: "12px" }}>
              <h2 className="mb-3">You May Also Like</h2>
            </div>
            <Col md={6}>
              <BlogCards
                goto={"/sales-and-stocks-analysis"}
                img="/assets/5.WebP"
                title="Sales and Stocks Analysis"
                height="344px"
              />
            </Col>
            <Col md={6}>
              <BlogCards
                goto={"/dynamics-of-retail-price-index-and-consumer-price"}
                img="/assets/3.WebP"
                title="Unveiling the Dynamics of Retail Price Index and Consumer Price Index: An In-Depth Analysis"
                height="344px"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <FooterImgSection mainTitle={mainTitle} />
    </>
  );
}

export default AwardWinning;
