import React from "react";
import styles from "./getInTouch.module.css";
import { Button } from "react-bootstrap";

const GetInTouch = () => {
  
    const handleNavigateContact = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/orbiosolutions",
    });
    return false;
  };

  return (
    <div className={styles.bannerContainer}>
      <h5 className={styles.bannerHeading}>Stay Ahead with OttixHow!</h5>
      <p className={styles.bannerPara}>
        Gain valuable insights from your competitors with OttixHow - Your
        competitor analysis platform.
      </p>
      <Button onClick={handleNavigateContact} variant="outline-light" className={styles.bannerBtn}>
        Start your FREE trial now
      </Button>
    </div>
  );
};

export default GetInTouch;
