import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./homesales.module.css";
import { GoDash } from "react-icons/go";

function HomeSalesSection() {
  return (
    <div className="mt-1">
      <Container>
        <Row className="d-flex align-items-center">
          <Col md={6} className="p-0 order-md-1 order-2">
            <Image
              src="assets/competitoranalysis.WebP"
              className={`${styles.img} animate__animated animate__zoomIn`}
            />
          </Col>
          <Col
            md={6}
            className={`order-md-2 order-1 ${styles.colPadding}`}
            style={{ paddingLeft: "30px" }}
          >
            <h5 className="d-flex align-items-center">
              <GoDash style={{ color: "#faa71c", fontSize: "25px" }} />
              SALES ANALYSIS
            </h5>
            <h2 className="mt-3 mb-3">
              Optimize Your Business with OttixHow Sales Analysis Dashboards
            </h2>
            <p>
              OttixHow offers comprehensive sales analysis dashboards that
              provide detailed insights into average monthly sales across
              various regions, stores, departments, and product types. Our
              advanced dashboards help businesses delve into historical demand
              patterns, understand customer behaviour, and spot emerging trends.
              This valuable data empowers you to optimize revenue, enhance
              profitability, manage inventory more effectively, and identify
              potential upsell opportunities. With OttixHow's intuitive
              interface and customizable reporting, you can make data-driven
              decisions swiftly and confidently.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeSalesSection;
