import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./hometool.module.css";

import { BsShop } from "react-icons/bs";
import { BsLightbulb } from "react-icons/bs";
import { LiaToolsSolid } from "react-icons/lia";
import { CiMusicNote1 } from "react-icons/ci";
import { FaAmbulance } from "react-icons/fa";

function HomeToolsSection() {
  return (
    <div>
      <Container>
      <h2 style={{color: "#faa61c"}} className="mb-3" >Industries We Serve</h2>
      </Container>
    <Container fluid>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Col md={2} className="d-flex justify-content-center" >
          <div className={`d-flex flex-column align-items-center ${styles.const}`}> 
            <BsShop className={`${styles.iconstyle}`}/>
            <h5 className="fs-6 mt-4 text-center">
              GROCERY
            </h5>
          </div>
        </Col>
        <Col md={2} className="d-flex justify-content-center">
          <div className={`d-flex flex-column align-items-center ${styles.const}`}> 
            <BsLightbulb className={`${styles.iconstyle}`} />
            <h5 className="fs-6 mt-4 text-center">
              HOUSEHOLD
            </h5>
          </div>
        </Col>
        <Col md={2} className="d-flex justify-content-center">
          <div className={`d-flex flex-column align-items-center ${styles.const}`}>
            <LiaToolsSolid className={`${styles.iconstyle}`} />
            <h5 className="fs-6 mt-4 text-center">
              HARDWARE
            </h5>
          </div>
        </Col>
        <Col md={2} className="d-flex justify-content-center">
          <div className={`d-flex flex-column align-items-center ${styles.const}`}> 
            <CiMusicNote1 className={`${styles.iconstyle}`} />
            <h5 className="fs-6 mt-4 text-center">
              INSTRUMENTS
            </h5>
          </div>
        </Col>
        <Col md={2} className="d-flex justify-content-center">
          <div className={`d-flex flex-column align-items-center ${styles.const}`}> 
            <FaAmbulance className={`${styles.iconstyle}`} />
            <h5 className="fs-6 mt-4 text-center">
              PHARMACY
            </h5>
          </div>
        </Col>
      </Row>
    </Container>
    </div>
  );
}

export default HomeToolsSection;
