import React from "react";
import styles from "./resource.module.css";
import { Col, Container, Row } from "react-bootstrap";

import BlogCards from "../../components/BlogCards";
import FooterImgSection from "../../components/FooterImgSection";
import { Helmet } from "react-helmet";

function Resource() {
  let mainTitle =
    "Enable business transformation with AI-driven retail insights.";

  const socialMedias = [
    { id: "0", url: "/assets/video/historical-price.mp4" },
    { id: "1", url: "/assets/video/historical-price.mp4" },
    { id: "2", url: "/assets/video/competitor-analysis.mp4" },
    { id: "3", url: "/assets/video/automatic-product-mapping.mp4" },
  ];

  const blogData = [
    {
      goto: "/unveiling-customer-insights-market-basket-analysis",
      img: "assets/card1.WebP",
      title:
        "Unveiling Customer Insights: Market Basket Analysis, Cannibalization, and Halo Effect",
    },
    {
      goto: "/leveraging-sales-analysis-and-competitor-price-index-for-business-growth",
      img: "assets/card2.WebP",
      title:
        "Leveraging Sales Analysis and Competitor Price Index for Business Growth",
    },
    {
      goto: "/dynamics-of-retail-price-index-and-consumer-price",
      img: "assets/card3.WebP",
      title:
        "Unveiling the Dynamics of Retail Price Index and Consumer Price Index: An In-Depth Analysis",
    },
    {
      goto: "/sales-and-stocks-analysis",
      img: "assets/card4.WebP",
      title: "Sales and Stocks Analysis",
    },
    {
      goto: "/award-winning-company-orbio-solutions",
      img: "assets/card5.WebP",
      title:
        "“Orbio Solutions: Pioneering AI-Driven Analytical Excellence with Award-Winning Product, OttixHow”",
    },
    {
      goto: "/amazon-price-tracking",
      img: "assets/pricetracking.WebP",
      title:
        "Amazon Price Tracking: Your Guide to Smart Pricing on the World's Largest Marketplace",
    },
  ];

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://ottixhow.com/resources" />
      </Helmet>
      <div className={styles.divContainer}>
        <div>
          <h1>Resources</h1>
          <h6>
            Explore our retail analysis platform and discover how it can outgrow
            your competitors.
          </h6>
        </div>
      </div>
      <div style={{ background: "#f6f6f6" }}>
        <Container>
          <Row>
            <Col sm={12} className="text-center mt-4">
              <h1 style={{ color: "#faa61c", marginBottom: "-.5rem" }}>
                We Develop & Create Digital Future
              </h1>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              <div className={styles.iframeContiner}>
                <iframe
                  title="YouTube Video"
                  src="https://www.youtube.com/embed/HWC_uVT_PBg?si=73T2Vts5tSphxZkf"
                  allowFullScreen
                  className="position-absolute top-0 start-0 w-100"
                  style={{ height: "80%" }}
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <h1 className="text-center mt-3 mb-2" style={{ color: "#faa61c" }}>
        Our Blog
      </h1>
      <Container className="mb-1">
        <Row style={{ display: "flex", flexWrap: "wrap" }}>
          {blogData.map((blog, index) => (
            <Col md={3} lg={3} sm={2} key={index}>
              <BlogCards goto={blog.goto} img={blog.img} title={blog.title} />
            </Col>
          ))}
        </Row>
      </Container>
      <div style={{ background: "#f6f6f6", padding: "26px", width: "100%" }}>
        <h1 className="text-center" style={{ color: "#faa61c" }}>
          Infographics
        </h1>
        <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
          {socialMedias?.map((obj) => (
            <div className={`${styles.videolink}`} key={obj.id}>
              <a
                href="https://www.instagram.com/ottixhow/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <video
                  autoPlay
                  muted
                  loop
                  style={{ height: "auto", width: "195px", margin: "12px" }}
                  src={obj.url}
                  preload="metadata"
                ></video>
              </a>
            </div>
          ))}
        </div>
      </div>
      <FooterImgSection mainTitle={mainTitle} />
    </div>
  );
}

export default Resource;
