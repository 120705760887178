import React from "react";
import { Button } from "react-bootstrap";
import styles from "./footerImg.module.css";

function FooterImgSection({ mainTitle, subTitle }) {
  const handleNavigateContact = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/orbiosolutions",
    });
    return false;
  };

  return (
    <div className={styles.bannerContainer}>
      <h5 className={styles.bannerHeading}>Get In Touch With Us Today!</h5>
      <div className={styles.bannerPara}>
        <h2>
          {mainTitle} {subTitle}
        </h2>
      </div>
      <Button
        onClick={handleNavigateContact}
        variant="outline-light"
        className={styles.bannerBtn}
      >
        Start your FREE trial now
      </Button>
    </div>
  );
}

export default FooterImgSection;
